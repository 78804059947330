import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components-app';
import { INFORMATION_RENT_ARREARS_SUPPORT } from './links';

export const PAGE_TITLE_APP = 'Wiener Wohnen Mieter*innenportal';
export const CUSTOMER_SERVICE_NUMBER = '+43 5 75 75 3700';
export const TENANT_PORTAL_SERVICE_NUMBER = '+43 5 75 75 75';
export const LAUNDRY_CUSTOMER_NUMBER = '+43 5 75 75 800';
export const MA40_NUMBER = '+43 1 40 00 80 40';
export const MA50_NUMBER = '+43 1 40 00 74 88 0';
export const SOCIAL_VIENNA_NUMBER = '+43 1 33 08 73 5';
export const CUSTOMER_SERVICE_MAIL = 'post@wrw.wien.gv.at';
export const HAUSBETREUUNG_MAIL = 'natuerlichsicher@hausbetreuung-wien.info';

export const CONTACT_CARD = {
  link: 'Alle Kontakte anzeigen',
};

export const ERROR_BOUNDARY = {
  title: 'Oh nein!',
  errorText: 'Leider kann zur Zeit nicht auf Ihre Daten zugegriffen werden. Bitte versuchen Sie es später noch einmal.',
  description: (
    <>
      Bei dringenden Fragen oder Problemen wenden Sie sich bitte an unsere Service-Nummer unter{' '}
      <Hyperlink
        id="error-boundary-service-number"
        to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}
        isInline={true}
        customHref={true}
      >
        {TENANT_PORTAL_SERVICE_NUMBER}
      </Hyperlink>
      .
    </>
  ),
  errorTitleTextAndDescription: null,
};

ERROR_BOUNDARY.errorTitleTextAndDescription = (
  <>
    <p>{ERROR_BOUNDARY.title}</p>
    <p>{ERROR_BOUNDARY.errorText}</p>
    <p>{ERROR_BOUNDARY.description}</p>
  </>
);

export const GENERAL_FAILURE = {
  title: 'Oh nein!',
  errorText: 'Das hat leider nicht geklappt. Bitte versuchen Sie es später noch einmal.',
  description: (
    <>
      Sollte der Fehler weiterhin bestehen, wenden Sie sich bitte an unseren Helpdesk unter{' '}
      <Hyperlink
        id="error-boundary-service-number"
        to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}
        isInline={true}
        customHref={true}
      >
        {TENANT_PORTAL_SERVICE_NUMBER}
      </Hyperlink>
      .
    </>
  ),
  errorTitleTextAndDescription: null,
};

GENERAL_FAILURE.errorTitleTextAndDescription = (
  <>
    <p>{GENERAL_FAILURE.title}</p>
    <p>{GENERAL_FAILURE.errorText}</p>
    <p>{GENERAL_FAILURE.description}</p>
  </>
);

export const COVID_SUPPORT_ANNOUNCEMENT = {
  badge: 'Information',
  title: 'COVID-19 Wohnungssicherung',
  description: (
    <p className={'RentalAccountAnnouncement__description'}>
      <span className={'RentalAccountAnnouncement__span'}>
        <em>Sie können aufgrund von COVID 19 Ihre Miete nicht bezahlen?</em>
        {'\n'}
        Hier erfahren Sie, was Sie tun können, um Ihre Wohnung zu behalten!
      </span>
      {'\n'}
      <Hyperlink
        id={'announcement-support'}
        to={INFORMATION_RENT_ARREARS_SUPPORT}
        isExternal={true}
        iconAfter={SVG.external_link}
        className={'RentalAccountAnnouncement__description-link'}
      >
        Hilfe bei Mietzinsrückstand
      </Hyperlink>
    </p>
  ),
  closeText: 'Information schließen',
};

export const PIPE_ANNOUNCEMENT = {
  linkText: 'Mehr erfahren',
};

export const MIN_VERSION_UPDATE = {
  badge: 'Bitte Aktualisieren',
  cardTitle: 'Es steht eine neue Version der App zur Verfügung',
  text:
    'Wir haben die Wiener Wohnen App um neue Funktionen erweitert. Um alle Funktionen ohne Einschränkung nutzen zu können, bitten wir Sie die App zu aktualisieren.',
  toStore: 'Zur Aktualisierung',
};
